.App {
  font-family: 'Arial', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.App-logo {
  height: 100px;
  pointer-events: none;
}


.App-header {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding-top: 40px;
  background-color: #96b5e2;
}

.App-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #96b5e200;
  padding-top: 30px;
  padding-bottom: 10px;
  background-color: #e0e7f2;
}

.App-search-header {
  position: sticky;
  top: 0;
  background-color: #050a30;
  height: 5px;
  z-index: 1;
}

ref-section {
  display: flex;
  justify-content: space-between;
}

.ref-item {
  margin-right: 20px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cy  {
  top: 0;
  right: 0; 
  bottom: 0;
  left: 1em;
  height: calc(100vh - 100px);
  display: block;
  z-index: 1;
}

.tooltip {
  position: absolute;
}